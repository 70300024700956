:root {
  --border-width: 1px;
}

@font-face {
  font-family: Cruiser;
  /*src: url('./2015\ Cruiser\ Bold.ttf');*/
}

@font-face {
  font-family: Prototype;
  src: url("./Prototype.ttf");
}

@font-face {
  font-family: Highland;
  src: url("./HighlandGothicFLF-Bold.ttf");
}

@font-face {
  font-family: Roboto;
  src: url("./Roboto-Light.ttf");
}

.main {
  overflow: hidden;
  z-index: 10;
  width: 100%;
  margin: auto;
  /* background-image: url('https://beta.pulzion.in/assets/images/home-blo-cyber-ecurity (1).png'); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  padding: 0 5%;
  height: 85vh;
}

.home-img {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.home-img-mob {
  position: absolute;
  bottom: 0;
  opacity: 0.5;
  display: none;
}

@media (max-width: 600px) {
  .home-img {
    display: none;
  }

  .home-img-mob {
    display: block;
  }

  .main {
    background-image: none;
  }
}

@media (min-width: 600px) and (min-height: 1000px) {
  .home-img {
    display: none;
  }

  .main {
    background-position: right;
    height: unset;
    padding-bottom: 20vw;
    background-size: contain;
  }
}

@keyframes switch {
  0% {
    opacity: 0;
    filter: blur(20px);
    transform: scale(2);
  }
  3% {
    opacity: 1;
    filter: blur(0);
    transform: scale(1);
  }
}

.main .title {
  font-family: Highland;
  /* font-weight: bold; */
  position: absolute;
  z-index: 100;
  /* font-size: 7vw; */
  text-align: left;
  margin: auto;
  margin-top: 0;
  color: #fff;
  letter-spacing: 0.5vw;
}

.main .title .hii {
  font-size: 7vw;
}

.main .subtitle {
  font-family: Highland;
  font-size: 2vw;
  text-align: left;
  margin: auto;
  color: rgba(22, 164, 189, 0.9);
  font-weight: lighter;
  margin-top: 18%;
}

.main .coming-soon {
  font-family: Highland;
  font-size: 1.5rem;
  text-align: left;
  margin: auto;
  color: rgba(22, 164, 189, 0.9);
  font-weight: lighter;
  margin-top: 35%;
}

.title {
  opacity: 1;
  animation: switch 12s linear;
}

/* 
.bgimg{
	background-image: linear-gradient(to top, rgba(14, 0, 0, 0.5), rgba(12, 0, 0, 0.856)),url('../Bg/home-blo-cyber-ecurity.png') !important;
  background-size: cover;
  background-attachment:fixed;
  background-repeat: no-repeat;
  background-color: #010d1a;
  background-position: right;
} */

.reg-btn button {
  position: relative;
  border: none;
  font-size: 1vw;
  transition: color 0.5s, transform 0.2s, background-color 0.2s;
  outline: none;
  border-radius: 10px;
  margin: 0 10px;
  padding: 1vw 1vw;
  border: 0.2vw solid transparent;
  margin-top: 7vw;
  letter-spacing: 0.3vw;
  font-weight: bold;
}

.reg-btn button::after,
.reg-btn button::before {
  border-radius: 3px;
}

.shrink-border {
  background-color: rgba(22, 164, 189, 0.9);
  color: #fff;
}

.shrink-border:hover {
  background-color: transparent;
  box-shadow: none;
  color: #ffffff;
}

.shrink-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border: 0.2vw solid #02fff218; */
  transition: opacity 0.3s, border 0.3s;
}

.shrink-border:hover::before {
  opacity: 0;
}

.shrink-border::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0.1vw solid #00ccff;
  opacity: 0;
  z-index: -1;
  transform: scaleX(1.1) scaleY(1.3);
  transition: transform 0.3s, opacity 0.3s;
}

.shrink-border:hover::after {
  opacity: 1;
  transform: scaleX(1) scaleY(1);
}

.material-bubble {
  background-color: transparent;
  color: #1b1b20;
  border: none;
  overflow: hidden;
  box-shadow: none;
}

.material-bubble:hover {
  color: #e6e6e6;
}

.material-bubble::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0.2vw solid #1b1b20;
  transition: opacity 0.3s, border 0.3s;
}

.material-bubble:hover::before {
  opacity: 0;
}

.material-bubble::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  background-color: #494958;
  border-color: transparent;
  border-radius: 50%;
  transform: translate(-10px, -70px) scale(0.1);
  opacity: 0;
  z-index: -1;
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}

.material-bubble:hover::after {
  opacity: 1;
  transform-origin: 100px 100px;
  transform: scale(1) translate(-10px, -70px);
}

.desktop-footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  margin: 0 !important;
  display: none;
}

.mobile-footer {
  display: block;
  padding: 10px 20px;
  margin: 0 !important;
  display: none;
}

@media (max-width: 1031px) {
  .mobile-footer {
    display: none;
  }

  .desktop-footer {
    display: none;
  }
}

.register-btn:hover {
  text-decoration: none;
  color: #1b96f3;
}

.mobile-view {
  display: none;
}

.main-left-div {
  flex: 1;
}

.main-right-div {
  flex: 1;
}

.main-right-div {
  overflow: hidden;
}

.design-block {
  display: none;
  height: 200px;
  width: 200px;
  position: absolute;
  background-color: #c3073f;
  top: 10%;
}

/* .hero-text {
  position: absolute;
  width: 60%;
  left: 8%;
  top: 15%;
  z-index: 100;
} */

.event-description {
  background-color: white;
  padding: 15px;
  position: absolute;
  font-weight: bold;
  font-size: 96%;
  width: 150px;
  height: 150px;
  right: 40px;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.event-description div:nth-child(1),
.event-description div:nth-child(2) {
  word-wrap: break-word;
}

.event-description .red-line {
  height: 5px;
  width: 70%;
  /* margin-left: 20px; */
  background-color: #1b96f3;
  margin-top: 5px;
}

.diamond-pattern {
  height: 200px;
  width: 200px;
  position: absolute;
  left: -50px;
  z-index: 990;
  bottom: -50px;
}

.register-btn {
  text-decoration: none;
  color: #1b96f3;
  z-index: 20;
  padding: 20px 40px;
  background-color: #2b2b2c;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
}

.register-btn-div {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 130%;
  background-color: #2b2b2c;
  text-align: center;
  width: 40%;
  margin-top: 53%;
  margin-left: 60%;
  transform: translateX(-50%);
  z-index: 20;
}

.mobile-register-btn-div {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 110%;
  background-color: #2b2b2c;
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.mobile-hero-text,
.mobile-20-text {
  display: flex;
  justify-content: center;
  padding-bottom: 35px;
}

@media only screen and (max-width: 1282px) {
  .main-right-div {
    flex: 2;
  }

  .text-20 {
    width: 102%;
    transform: translateX(10%);
    z-index: 1;
  }

  .register-btn-div {
    margin-top: 100%;
    width: 60%;
    font-size: 105%;
  }
}

@media only screen and (max-width: 1030px) {
  /* .main-right-div{
            flex: 2;
        } */
  .hero-text {
    width: 60%;
  }

  .text-20 {
    width: 100%;
    height: 90%;
    transform: translateX(80px);
    z-index: 1;
  }

  .register-btn-div {
    margin-top: 100%;
    width: 70%;
    font-size: 105%;
  }
}

@media only screen and (max-width: 949px) {
  .main-right-div {
    flex: 2;
  }

  .text-20 {
    width: 102%;
    height: 85%;
    transform: translateX(10%);
    z-index: 1;
  }

  .register-btn-div {
    margin-top: 127%;
    width: 70%;
    font-size: 105%;
  }
}

@media only screen and (max-width: 772px) {
  .register-btn-div {
    width: 90%;
    font-size: 100%;
  }
}

@media only screen and (max-width: 609px) {
  .diamond-pattern {
    height: 150px;
    width: 150px;
    left: -30px;
    bottom: -30px;
  }

  .main {
    flex-direction: column;
  }

  .mobile-view {
    display: block;
    margin-top: 20px;
  }

  .main-right-div,
  .main-left-div {
    display: none;
  }

  .event-description {
    height: 100px;
    width: 100px;
    font-size: 70%;
    padding: 13px;
    right: 25px;
    bottom: 25px;
  }
}

@media only screen and (max-width: 609px) and (min-width: 350px) {
  .mobile-view {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

/* .reg-btn button-animation */

.mobile-register-btn-div p,
.register-btn-div p {
  margin: 0 !important;
}

.mobile-register-btn-div::after,
.register-btn-div::after {
  position: absolute;
  content: "";
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  z-index: 1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient(
    60deg,
    hsl(224, 85%, 66%),
    hsl(269, 85%, 66%),
    hsl(314, 85%, 66%),
    #5f9ff2,
    hsl(239, 85%, 66%),
    hsl(89, 85%, 66%),
    hsl(199, 85%, 66%),
    hsl(179, 85%, 66%)
  );
  background-size: 300% 300%;
  background-position: 0 50%;
  border-radius: calc(2 * var(--border-width));
  animation: moveGradient 4s alternate infinite;
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

#your-element-selector {
  width: 100vw;
}

.subtitle {
  position: absolute;
  z-index: 10;
  margin-top: 35%;
}

.coming-soon {
  position: absolute;
  z-index: 10;
  margin-top: 45%;
}

@media (max-width: 600px) {
  #your-element-selector {
    width: 98vw;
    opacity: 0.6;
  }

  .main {
    margin: 0 auto;
    text-align: center;
    opacity: 1;
  }

  .main .title {
    text-align: center;
    width: 90vw;
    /* font-size: 12vw; */
    margin-bottom: unset;
    margin-top: 25%;
  }

  .main .title .hii {
    font-size: 12vw;
  }

  .main .subtitle {
    text-align: center;
    width: 90vw;
    padding: 0;
    font-size: 4.5vw;
    margin-bottom: unset;
    margin-top: 70vw;
    color: #fff;
  }
  .main .coming-soon {
    text-align: center;
    width: 90vw;
    padding: 0;
    font-size: 4.5vw;
    margin-bottom: unset;
    margin-top: 59vh;
    color: #fff;
  }
  /* style={{ marginTop: "35%", fontSize: "1.5rem" }} */

  .reg-btn button {
    font-size: 4.3vw;
    padding: 5.5vw 4vw;
    border: 0.2vw solid transparent;
    letter-spacing: 0.3vw;
    margin: auto;
  }

  .reg-btn {
    margin-top: 20vw;
    opacity: 1;
    position: absolute;
    bottom: 5vh;
    left: 30%;
    border: 0.3vw solid #00ccff;
    border-radius: 1vw;
  }

  .shrink-border {
    background-color: #0000009a;
    color: rgba(22, 164, 189, 0.9);
  }

  .shrink-border:hover {
    background-color: transparent;
    box-shadow: none;
    color: #ffffff;
  }
}

.title img {
  width: 60%;
}

.subtitle img {
  width: 75%;
}

@media (max-width: 600px) {
  .subtitle img {
    width: 40%;
  }
  .coming-soon {
    display: none;
  }
}
