.sponsor {
    background-color: unset;
    text-align: center;
    color: #FFFFFF;
    width: 90%;
    margin: auto;
}

.sponsor .title {
    font-size: 3.5vw;
    font-weight: 600;
    color: #66ffff;
    margin-bottom: 3vw;
}