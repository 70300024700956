@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* border: solid 1px red; */
}

body {
    overflow-x: hidden !important;
    margin: 0;
    max-width: 100vw !important;
}

/* width */
::-webkit-scrollbar {
    width: 12px;
    background: black;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(22, 164, 189, 0.9);
    border-radius: 10px;
}

.nGY2GThumbnail {
    border-color: transparent !important;
    border-radius: 10px !important;
}

.privacy-policy {
    color: white;
    margin: 5%;
}

.Page404 {
    text-align: center;
    color: white;
    margin: 5%;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
@font-face {
    font-family: SegoeUI !important;
    src: local("Segoe UI Light"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf) format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: SegoeUI;
    src: local("Segoe UI Semilight"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf) format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: SegoeUI;
    src: local("Segoe UI"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: SegoeUI;
    src: local("Segoe UI Semibold"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf) format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: SegoeUI;
    src: local("Segoe UI Bold"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf) format("truetype");
    font-weight: 700;
}

body {
    font-family: "Segoe UI";
}

.App {
    background-color: #000000;
    width: 100vw;
    min-height: 100vh;
    /* background-attachment:fixed; */
    /* display: flex;
    flex-direction: column; */
    margin: 0;
    overflow-x: hidden !important;
}

/* 
@media (max-width:600px){
  .App{
    background-image: linear-gradient(to top, rgba(14, 0, 0, 0.5), rgba(12, 0, 0, 0.856)),url('./Bg/mobilebg.png') !important;
    background-position: center;

  }
} */

footer {
    padding: 20px 0;
    text-align: center;
    color: white;
    margin-bottom: 30px;
    margin-top: auto;
    display: none;
}

@media (max-height: 740px) {
    .App {
        min-height: 100vh;
    }
}

@media (max-height: 570px) {
    .App {
        height: 100vh;
    }
}

.reg-button {
    margin: auto;
    text-align: center;
    margin: 5vw 0;
    font-size: 1.5vw;
}

.page-title {
    /* font-family: Cruiser !important; */
    font-family: "Montserrat", sans-serif !important;
    color: rgb(0, 216, 224) !important;
    font-size: 3vw !important;
    margin: 2vw 0;
}

@media (max-width: 600px) {
    .page-title {
        font-size: 8vw !important;
        margin: 6vw 0;
    }
}

.nav-bar {
  color: white;
  padding: 2vw 2vw 2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  height: 10vh;
}

.nav-bar.scrolled {
  background-color: #000 !important;
  transition: background-color 200ms linear;
}

.nav-links {
  flex: 2 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.nav-links .nav-link,
.login-btn {
  color: rgba(255, 255, 255, 0.603);
  text-decoration: none;
  font-weight: normal;
  margin: 0 1%;
  padding: 5px 20px;
  /* border: 1px solid rgb(187, 187, 187); */
  border-radius: 20px;
  font-size: 100%;
}

.nav-link:after,
.login-btn:after {
  background: none repeat scroll 0 0 transparent;
  bottom: -0.5vw;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: relative;
  background: rgba(13, 134, 150, 0.493);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
  color: #fff;
}

.nav-link a:hover {
  color: #fff;
}

.nav-link:hover:after,
.login-btn:hover:after {
  width: 100%;
  color: #fff;
  left: 0;
}

.nav-link:hover {
  color: #fff;
}

.login-btn-div {
  display: flex;
  flex-direction: row;
}

.login-btn {
  background-color: rgba(13, 134, 150, 0.493);
  border-radius: 2vw;
}

.login-btn-div a:hover {
  text-decoration: none;
  color: #fff;
}

.selected {
  color: #fff !important;
  font-weight: bold;
  background-color: rgba(22, 164, 189, 0.767);
}

.main-logo b {
  font-size: 180%;
}

.navbar-toggle-icon {
  display: none;
}

.mobile-menu {
  font-family: Roboto;
  z-index: 10000;
  position: fixed;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.801);
  box-shadow: 0 8px 32px 0 rgba(0, 1, 20, 0.37);
  backdrop-filter: blur(12.5px);
  -webkit-backdrop-filter: blur(12.5px);
  font-size: 4vw;
  /* border-radius: 10px; */
}

.close-menu {
  position: absolute;
  top: 30px;
  right: 30px;
}

.mobile-menu ul {
  list-style: none;
  position: absolute;
}

.mobile-menu ul li {
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

.mobile-menu ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  padding: 5px 20px;
  /* border: 1px solid rgb(187, 187, 187); */
  /* border-radius: 20px; */
}

@media only screen and (max-width: 1100px) {
  .nav-links {
    display: none;
  }

  .navbar-toggle-icon {
    display: inline;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 609px) {
  .mobile-menu {
    display: flex;
  }
}

@media (max-width: 600px) {
  .nav-bar {
    padding: 3vw 5vw;
    background-color: #000;
  }

  .login-btn {
    border-radius: 5vw;
  }

  .login-btn-div {
    max-height: 10vh;
  }
}

#notification {
  cursor: pointer;
}

#notification:hover {
  background: #333;
}

.desktop-only {
  display: block;
}

@media (max-width: 600px) {
  .desktop-only {
    display: none;
  }
}

/* KEYFRAMES */

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(359deg);
    }
}

@keyframes spin3D {
    from {
        transform: rotate3d(.5, .5, .5, 360deg);
    }
    to {
        transform: rotate3d(0, 0, 0, 0deg);
    }
}

@keyframes configure-clockwise {
    0% {
        transform: rotate(0);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes configure-xclockwise {
    0% {
        transform: rotate(45deg);
    }
    25% {
        transform: rotate(-45deg);
    }
    50% {
        transform: rotate(-135deg);
    }
    75% {
        transform: rotate(-225deg);
    }
    100% {
        transform: rotate(-315deg);
    }
}

@keyframes pulse {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: .25;
        transform: scale(.75);
    }
}

/* GRID STYLING */

* {
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    background-color: #1d2630;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
}

.preloader {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #000;
    z-index: 99999;
}

.spinner-box-loader {
    /* width: 100%;
    height: 70%; */
    margin: 300px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.leo-loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.blue-orbit-loader {
    width: 400px;
    height: 400px;
    border: 3px solid #91daffa5;
    animation: spin3D 3s linear .2s infinite;
}

.green-orbit-loader {
    width: 340px;
    height: 340px;
    border: 3px solid #91ffbfa5;
    animation: spin3D 2s linear 0s infinite;
}

.red-orbit-loader {
    width: 270px;
    height: 270px;
    border: 3px solid #ffca91a5;
    animation: spin3D 1s linear 0s infinite;
}

.white-orbit-loader {
    width: 150px;
    height: 150px;
    border: 3px solid #ffffff;
    animation: spin3D 10s linear 0s infinite;
}

.w1-loader {
    transform: rotate3D(1, 1, 1, 90deg);
}

.w2-loader {
    transform: rotate3D(1, 2, .5, 90deg);
}

.w3-loader {
    transform: rotate3D(.5, 1, 2, 90deg);
}
* {
    margin: 0px;
    padding: 0px;

}

body {
    flex: content;
    padding: 0;
    margin: 0;
    background: #1A1A1D;
    overflow-x: hidden;
    font-family: 'Segoe UI', 'sans-serif';
    z-index: 1;
    overflow-x: hidden;
}


.box:hover {
    transform: scale(1.07);
    transition-duration: 0.5s;
    z-index: 1;
    box-shadow: 0 8px 50px rgba(13, 105, 224, 0.904);
}


@media (max-width: 451px) {
    .heroText {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;
        text-shadow: 2px 2px rgba(13, 105, 224, 0.904);
        font-size: 60px;
        word-break: break-word;
    }

    .box {
        margin-bottom: 30px;
    }
}

@media (min-width: 452px) and (max-width: 768px) {
    .heroText {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;
        text-shadow: 4px 5px rgba(13, 105, 224, 0.904);
        font-size: 80px;
        word-break: break-word;
    }

}

@media (min-width: 769px) {
    .heroText {
        margin-top: 50px;
        margin-bottom: 80px;
        margin-left: auto;
        margin-right: auto;
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;
        text-shadow: 4px 3px rgba(13, 105, 224, 0.904);
        word-break: break-word;
    }

}


.context {
    width: 100%;
    position: absolute;
    top: 50vh;
    z-index: -1;
}

.context h1 {
    text-align: center;
    color: #fff;
    font-size: 50px;
    z-index: -1;
}

/*
background - #1A1A1D
text '20' color - #C3073F
font - Segoe UI
*/

* {
    box-sizing: border-box;
}

/* elements */

body {
    font-family: "Segoe UI", sans-serif;
    background: #1a1a1d;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 16px;
}

h1 {
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 45px !important;
}

.para {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0.5px !important;
    margin: 20px 0 30px !important;
    line-height: 30px !important;
}

.link {
    color: #1b96f3;
    font-size: 14px;
    text-decoration: none !important;
    margin: 15px 0px 40px 0px;
    font-weight: 500 !important;
}

.container-block {
    background: #000 !important;
    border-radius: 10px !important;
    box-shadow: 0px 0px 3vw rgba(71, 197, 255, 0.247) !important;
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    overflow: hidden !important;
    width: 800px !important;
    max-width: 100% !important;
    min-height: 550px !important;
}

footer {
    bottom: -20% !important;
}

/* input */

input[type="text"]::-webkit-input-placeholder {
    color: #5bb7fde7;
    font-weight: 400 !important;
}

input[type="text"]:-ms-input-placeholder {
    color: #5bb7fde7;
    font-weight: 400 !important;
}

input[type="text"]::-ms-input-placeholder {
    color: #5bb7fde7;
    font-weight: 400 !important;
}

input[type="text"]::placeholder {
    color: #5bb7fde7;
    font-weight: 400 !important;
}

input[type="email"]::-webkit-input-placeholder {
    color: #5bb7fde7;
    font-weight: 400 !important;
}

input[type="email"]:-ms-input-placeholder {
    color: #5bb7fde7;
    font-weight: 400 !important;
}

input[type="email"]::-ms-input-placeholder {
    color: #5bb7fde7;
    font-weight: 400 !important;
}

input[type="email"]::placeholder {
    color: #5bb7fde7;
    font-weight: 400 !important;
}

input[type="password"]::-webkit-input-placeholder {
    color: #5bb7fde7;
    font-weight: 400 !important;
}

input[type="password"]:-ms-input-placeholder {
    color: #5bb7fde7;
    font-weight: 400 !important;
}

input[type="password"]::-ms-input-placeholder {
    color: #5bb7fde7;
    font-weight: 400 !important;
}

input[type="password"]::placeholder {
    color: #5bb7fde7;
    font-weight: 400 !important;
}

input:focus {
    outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px black inset !important;
    -webkit-text-fill-color: #fff !important;
}

/*input:-webkit-internal-autofill-selected {*/
/*  appearance: menulist-button;*/
/*  !* background-color: rgb(232, 240, 254) !important; *!*/
/*  background-color: #1b96f3 !important;*/
/*  background-image: none !important;*/
/*  color: -internal-light-dark(black, white) !important;*/
/*}*/

/* form */
.form-container-block {
    position: absolute;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.form-container-block form {
    background: #000;
    padding: 0 50px;
    color: #6699cc;
    align-items: center;
    text-align: center;
}

.form-container-block form input {
    color: #fff;
    font-size: 15px;
    letter-spacing: 1.5px;
    caret-color: #fff;
    font-weight: 500;
}

.form-container-block input {
    background: #000;
    border-color: #000;
    border-bottom: 1px solid #6699cc;
    padding: 12px 15px;
    margin: 12px 0;
    width: 100%;
}

.sign-up-container::-webkit-scrollbar {
    width: 5px;
    background-color: black;
}

.sign-up-container::-webkit-scrollbar-thumb {
    background-color: #6699cc;
}

/* buttons */
.button {
    border-radius: 2vw;
    border: 2px solid #6699cc;
    box-shadow: 0px 0px 1vw #6699cc;
    background: #000;
    color: #6699cc;
    font-size: 1.2vw;
    font-weight: normal;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    margin-top: 20px;
}

button:active {
    transform: scale(0.95);
    box-shadow: 0px 0px 10px #1b96f3;
}

button:focus {
    outline: none !important;
}

button:hover {
    cursor: pointer;
}

button .ghost {
    background: transparent;
    border-color: #fff;
}

#signup-btn {
    margin-top: 40px;
    margin-bottom: 40px;
}

#signin-btn {
    margin-top: 60px;
}

/* icons */
.sign-up-container i {
    display: none;
}

.fa-arrow-circle-left {
    font-size: 20px;
    color: #032c55;
    position: absolute;
    top: 5%;
    left: 5%;
}

.fa-arrow-circle-left:hover {
    cursor: pointer;
}

/* signIn container */
.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
    margin-top: 50px;
}

/* signUp container */
.sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
    /* overflow: auto; */
}

.sign-up-container input:last-of-type {
    margin-bottom: 10px;
}

/* overlay container */
.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 50%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.overlay {
    background: #000;
    background: linear-gradient(to right, #00ccff, #2e6674, #00ccff) no-repeat 0 0 /
    cover;
    color: #170d22;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-panel {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    height: 100%;
    width: 50%;
    text-align: center;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-panel input {
    background: #000;
    border-radius: 20px;
    border: 2px solid #1b96f3;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
    color: #1b96f3;
    font-size: 15px;
    letter-spacing: 1.5px;
}

.overlay-right {
    right: 0%;
    transform: translateX(0);
}

.overlay-left {
    transform: translateX(-20%);
}

.register-now {
    display: none;
}

/* Errors */
.error {
    font-size: 12px !important;
    color: rgb(255, 94, 94);
    font-weight: 600;
    text-align: left;
    margin-left: 10px;
    letter-spacing: 0.4px;
}

#drop-down {
    color: #5bb7fde7;
    font-size: 15px;
    background-color: #000;
    border-color: #000;
    border-bottom: 1px solid #1b96f3;
    padding: 14px 15px 12px !important;
    margin: 12px 0 !important;
    width: 100%;
    letter-spacing: 1.5px;
}

#year {
    border: 1px solid #5bb7fde7;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #1b96f3;
}

#drop-down:focus {
    outline: none;
}

#drop-down:hover {
    cursor: pointer;
}

.main-error {
    margin-top: 30px;
    margin-bottom: -20px;
    text-align: center;
}

/* //Animation */
/* Move signin to right */
.container-block.right-panel-active .sign-in-container {
    transform: translateX(100%);
}

/* move overlay to left */
.container-block.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

/* Bring Sign Up over sign in */
.container-block.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 10;
}

/* Move overlay back to right */
.container-block.right-panel-active .overlay {
    transform: translateX(50%);
}

.container-block.right-panel-active .overlay-left {
    transform: translateX(0);
}

.container-block.right-panel-active .overlay-right {
    transform: translateX(20%);
}

@media only screen and (max-width: 1080px) {
    /* footer */
    footer {
        bottom: 0% !important;
    }
}

@media only screen and (max-width: 800px) {
    /* container */
    .overlay-container {
        display: none;
    }

    .container-block {
        width: 500px !important;
        top: 50% !important;
        height: 75%;
        transform: translate(-50%, -45%) !important;
    }

    .form-container-block {
        width: 100%;
        padding: 0px 30px;
    }

    .sign-up-container {
        opacity: 0;
        transform: rotateY(-180deg);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
    }

    /* footer */
    footer {
        bottom: 0% !important;
    }

    /* icon */
    .sign-up-container i {
        display: block;
        top: 5%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 30px;
    }

    /* Heading */
    h1 {
        margin-bottom: 30px !important;
        font-size: 60px !important;
    }

    /* Button */
    .button {
        border-radius: 20px !important;
        border: 2px solid #1b96f3;
        font-size: 15px;
        margin-top: 0px;
    }

    #signin-btn {
        margin-top: 20px;
    }

    /* Form */
    .signup-form {
        margin-top: 80px;
    }

    /* Input */
    .form-container-block input {
        padding: 6px 7.5px;
        margin: 10px 0;
    }

    .form-container-block form input {
        font-size: 20px !important;
    }

    /* Others */
    .link {
        display: block;
        font-size: 18px;
        margin: 15px 0px 20px 0px !important;
    }

    .error {
        font-size: 15px !important;
    }

    .register-now {
        display: block;
        position: absolute;
        left: 50%;
        bottom: 10%;
        transform: translate(-50%, -50%);
        text-align: center !important;
    }

    .register-now p {
        font-size: 20px !important;
        margin: 10px 0px 0px;
        color: #1b96f3;
    }

    #drop-down {
        padding: 6px 7.5px 6px !important;
        display: block;
        margin: 9px 0px !important;
        font-weight: 500 !important;
        font-size: 20px;
        width: 50%;
        text-align: left;
    }

    #year {
        border: 1px solid #5bb7fde7;
        border-radius: 5px;
        box-shadow: 0px 0px 10px #1b96f3;
        width: 90%;
        padding-left: 10px;
    }
}

@media only screen and (max-width: 600px) {
    /* Container */
    .container-block {
        width: 350px !important;
        height: 80% !important;
        top: 55% !important;
    }

    .form-container-block {
        width: 100%;
        padding: 0px 0px;
    }

    /* footer */
    footer {
        bottom: -20% !important;
    }

    /* Icon */
    .sign-up-container i {
        font-size: 25px;
    }

    /* Heading */
    h1 {
        font-size: 40px !important;
    }

    /* Button */
    .button {
        border: 1px solid #1b96f3;
        font-size: 12px;
    }

    /* Form-Input */
    .form-container-block form input {
        font-size: 15px !important;
    }

    /* Others */
    .link {
        font-size: 15px;
    }

    .register-now {
        bottom: 2%;
        margin-bottom: 20px;
    }

    .register-now p {
        font-size: 15px !important;
        margin: 10px 0px 10px !important;
    }

    .error {
        font-size: 12px !important;
    }

    #drop-down {
        font-size: 15px;
    }
}

@media only screen and (max-width: 500px) {
    /* container */
    .container-block {
        width: 300px !important;
        height: 75% !important;
        top: 50% !important;
    }

    body {
        overflow: auto;
    }

    .form-container-block {
        width: 100%;
        padding: 0px 30px;
    }

    /* footer */
    footer {
        bottom: -5% !important;
    }

    /* Heading */
    h1 {
        margin-bottom: 20px !important;
        font-size: 30px !important;
    }

    /* icon */
    .sign-up-container i {
        font-size: 25px;
    }

    /* button */
    .button {
        font-size: 10px;
        padding: 10px 20px;
        border-radius: 10px !important;
    }

    /* form */
    .signup-form {
        margin-top: 80px;
    }

    .form-container-block form {
        padding: 0 0px;
    }

    /* form-input */
    .form-container-block input {
        padding: 6px 7.5px;
        margin: 10px 0;
    }

    .form-container-block form input {
        font-size: 15px !important;
    }

    .link {
        display: block;
        font-size: 12px;
        margin: 15px 0px 20px 0px !important;
    }

    .register-now {
        bottom: 2%;
    }

    .register-now p {
        font-size: 12px !important;
        margin: 10px 0px 10px !important;
    }

    .error {
        font-size: 12px !important;
    }

    #drop-down {
        font-weight: 400 !important;
        font-size: 15px;
    }
}

@media only screen and (max-width: 325px) {
    /* container */
    .container-block {
        position: relative;
        width: 250px !important;
        top: 55% !important;
        height: 80% !important;
    }

    .sign-in-container {
        height: 100% !important;
    }

    /* body */
    body {
        overflow: visible;
    }

    /* footer */
    footer {
        bottom: -22% !important;
    }

    /* icon */
    .sign-up-container i {
        font-size: 20px;
    }

    /* button */
    .button {
        border-radius: 10px !important;
        font-size: 10px;
        padding: 10px 20px;
    }

    /* input */
    .form-container-block form input {
        font-size: 12px !important;
    }

    .register-now p {
        font-size: 10px !important;
        margin: 10px 0px 10px !important;
    }

    .error {
        font-size: 9px !important;
    }
}

.footer {
    bottom: 0;
    text-align: center;
    margin-top: auto;
    color: #777e7e;
    font-size: 1vw;
    padding: 3vw 0 0.5vw;
    position: static;
}

@media (max-width: 600px) {
    .footer {
        font-size: 3vw;
        padding: 10vw 3vw 2vw;
    }
}

.about {
    background-color: #000;
    padding: 1vw 5vw;
    /* margin-top: 5vw; */
    margin-bottom: 5vw;
    /* background-image: linear-gradient(to bottom, black, rgba(0, 0, 0, 0.274), black), url('../../Bg/bg31.jpg'); */
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
}

.about-title {
    font-size: 2vw;
    font-family: Cruiser;
    letter-spacing: 0.1vw;
    color: #69c0cc;
    text-align: center;
    margin-bottom: 4vw;
}

.about-desc {
    font-size: 1.5vw;
    font-family: Roboto;
    text-align: left;
    padding-right: 3vw;
    color: rgb(196, 194, 194);
    letter-spacing: 1px;
}

/* .about .numbers{
    width: 60%;
    margin: auto;
} */

.about .number-box {
    border-radius: 1vw;
    padding: 1.5vw 0;
    font-family: Prototype;
    color: #fff;
    font-size: 2vw;
}

.about .box-icon {
    background-size: cover;
    font-size: 2vw;
    height: 9vw;
    color: rgba(255, 255, 255, 0.774);
    font-family: unset;
    background-color: rgba(0, 0, 0, 0.418);
    line-height: unset;
    align-items: center;
    display: flex;
    justify-content: center;
}

.about .number-box .box-num {
    font-size: 2vw;
    color: rgb(189, 2, 2);
    background-color: violet;
    height: 10vw;
}

.about .box-name {
    font-size: 1.2vw;
    font-weight: bold;
    color: rgb(255, 255, 255);
    font-family: Roboto;
    background-color: rgb(236, 6, 6);
    width: 100%;
}

/* .about .numbers .row:nth-child(2){
    flex-direction: row-reverse;
} */

/* .about .numbers .row:nth-child(2n+1) .triangle{
    position: absolute;
    right: 49%;
    margin-top: 7.5%;
    border-top: 1vw solid transparent;
	border-right: 1.5vw solid rgb(236, 6, 6);
	border-bottom: 1vw solid transparent;
}

.about .numbers .row:nth-child(2n) .triangle{
    position: absolute;
    left: 49%;
    margin-top: 7.5%;
    border-top: 1vw solid transparent;
	border-left: 1.5vw solid rgb(236, 6, 6);
	border-bottom: 1vw solid transparent;
} */

.about-link {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-left: auto;
}

.about .about-link a {
    color: #69c0cc;
    font-family: Roboto;
    text-decoration: none;
    margin: 0 2vw;
}

.about .about-link a:hover {
    border-radius: 20vw;
    color: aqua;
}

.about-link a:before,
.about-link a:after {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #69c0cc;
    width: 50%;
}

.about-link a:before {
    opacity: 0;
    transform: translateY(-1.5vw);
    transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s;
}

.about-link a:after {
    opacity: 0;
    transform: translateY(1vw);
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0.2s;
}

.about-link a:hover:before,
.about-link a:hover:after,
.about-link a:focus:before,
.about-link a:focus:after {
    opacity: 1;
    transform: translateY(0);
}

.about-link a:hover:before,
.about-link a:focus:before {
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0.2s;
}

.about-link a:hover:after,
.about-link a:focus:after {
    transition: transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0s 0.2s;
}

@media (max-width: 600px) {
    .about {
        margin-top: 30vw;
    }

    .about-title {
        font-size: 6vw;
    }

    .about-desc {
        font-size: 4vw;
        text-align: center;
        padding: 5vw 0;
    }
}

.loading {
    height: 10vw;
    width: 10vw;
    position: relative;
}

.loading:nth-child(2n) {
    top: 20%;
    left: 20%;
    transform: translate(0%, -50%);
}

.loading:nth-child(2n + 1) {
    margin-top: 20%;
    left: 20%;
    transform: translate(0%, 50%);
}

.loading:after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: " ";
    box-shadow: 0 0 0 2px rgba(0, 225, 255, 0.712);
    transform: rotate(45deg);
    background-color: rgba(1, 30, 34, 0.281);
}

.dots {
    text-align: center;
    margin-top: 1.5vw;
}

@keyframes dot-show-hide-1 {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    20% {
        opacity: 1;
    }

    30% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    60% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    80% {
        opacity: 0;
    }

    90% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes dot-show-hide-2 {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    30% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    60% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    80% {
        opacity: 0;
    }

    90% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes dot-show-hide-3 {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    40% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    60% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    80% {
        opacity: 0;
    }

    90% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.dots i {
    display: inline-block;
    vertical-align: top;
    height: 3px;
    width: 3px;
    background: #fff;
    margin: 0 8px;
    opacity: 0;
}

.dots i:nth-child(1) {
    animation: dot-show-hide-1 1.5s linear infinite forwards;
}

.dots i:nth-child(2) {
    animation: dot-show-hide-2 1.5s linear infinite forwards;
}

.dots i:nth-child(3) {
    animation: dot-show-hide-3 1.5s linear infinite forwards;
}

.dots i.stop-animating {
    animation-play-state: paused;
}

.numbers span {
    font-family: "Abel";
    font-size: 2vw !important;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    font-weight: bold;
    transform: translatey(-50%);
    margin-top: -1.5vw;
    color: #fff;
}

.name {
    color: #fff;
    font-size: 1.2vw;
    margin-top: 2vw;
}

@media (max-width: 600px) {
    .number {
        padding: 5vw 0;
    }

    .loading {
        width: 20vw;
        height: 20vw;
    }

    .loading:nth-child(2n + 1) {
        left: 18%;
    }

    .loading:nth-child(2n) {
        left: 18%;
    }

    .numbers span {
        font-size: 5.5vw !important;
        margin-bottom: 2vw;
    }

    .name {
        font-size: 3vw;
        position: absolute;
        top: 47%;
        left: 50%;
        transform: translateX(-50%);
    }

    .dots i {
        margin: 1vw;
    }
}

.main-about {
    flex: 2 1;
    overflow: hidden;
    z-index: 10 !important;
}

/*
span {
  font-size: 18px;
} */

.btn-about {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    outline: none !important;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.container-about {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

/* body {
  font-family: 'Roboto Slab', serif;
  color: white;
  background-color: black;
} */

section {
    margin-top: 4rem;
    margin-bottom: 7rem;
}

h1 {
    font-size: 3rem;
    margin-bottom: 0;
    margin-top: 0;
}

h2 {
    padding-top: 3%;
    font-size: 32px;
}

/* Heading */

.main-h1-div {
    text-align: center;
    position: absolute;
    /* border:3px solid #1b96f3; */
    width: 300px;
    /* border-radius:20px; */
    left: 50%;
    margin: -50px 0 0 -150px;
    /* -moz-box-shadow: 0px 0px 5px 2px #1b96f3;
      box-shadow: 0px 0px 5px 2px #1b96f3; */
}

.About-Us {
    color: #66ffff;
    font-size: 3rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.header {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: white;
}

.haeder-block {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #1b96f3;
}

/* Pulzion */
.pulzion-bg-box {
    background-color: #004d88;
    margin-top: 10px;
    padding: 0px;
    height: 285px;
    border-radius: 5%;
}

.pulzion-div {
    margin: auto;
    display: block;
    margin-top: -14px;
    max-width: 75%;
    text-align: center;
    border-radius: 6%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    background-color: #1b96f3;
    margin-bottom: 50px;
    border-top: 15px solid #1a1a1a;
    border-bottom: 15px solid #1a1a1a;
    border-right: 5px solid #1a1a1a;
    border-left: 5px solid #1a1a1a;
}

.pulzion-row-small {
    display: unset;
}

.pulzion-heading {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-top: -10px;
    font-size: 225%;
    color: #0c314e;
}

.pulzion-text {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 3%;
    font-size: 118%;
    text-align: center;
    color: #0c314e;
}

/* Pasc and ACM */
.col-lg-4 {
    text-align: center;
    margin-bottom: 2rem;
}

.about-div {
    margin: auto;
    margin-bottom: 2rem;
    max-width: 315px;
    text-align: center;
    /* border: 2px solid #1b96f3; */
    border-radius: 0.6vw;
    display: block;
    /* animation: animate 3s ease-in-out infinite; */
    /* animation: border-flicker 3s linear infinite; */
    background-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.548),
            rgba(0, 0, 0, 0.425)
    ),
    url(/static/media/footer_lodyas.be8d2259.png);
    padding: 2vw;
    height: 100%;
}

.about-text {
    font-size: 18px;
    color: white;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: lighter;
}

#more1 {
    display: none;
}

#more2 {
    display: none;
}

#more3 {
    display: none;
}

.pasc-img {
    margin-top: 1rem;
    max-width: 70%;
}

.acm-img {
    max-width: 50%;
}

.acmw-img {
    max-width: 70%;
}

#pasc-acm-acmw-section {
    margin-bottom: 1rem;
}

.circle {
    margin: auto;
    margin-bottom: 15px;
    width: 130px;
    height: 130px;
    text-align: center;
    opacity: 0.8;
    background-image: url(/static/media/dark-honeycomb.b591ad61.png);
    border: 3px solid #1b96f3;
    border-radius: 100px;
    box-shadow: 0px 0px 20px 4px #1b96f3;
    position: relative;
    animation: animatecircle1 2s ease-in-out infinite;
}

.members {
    animation: animatecircle1 2s ease-in-out infinite;
}

.events {
    animation: animatecircle1 2s ease-in-out infinite;
}

.footfall {
    animation: animatecircle2 2s ease-in-out infinite;
}

.icon {
    margin-top: 15%;
    text-decoration: none;
}

/* Contact-us */
#contact-us-section {
    margin-bottom: 0;
}

.Contact-Us {
    text-align: center;
    margin-bottom: 100px;
    color: white;
}

.contact-us-container {
    text-align: center;
    padding-bottom: 4rem;
}

.contact-us-text {
    color: white;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 15px;
    font-size: 0.75rem !important;
}

/* Address */
.address-content {
    text-align: center;
    margin: 0px;
    display: block;
}

@media screen and (max-width: 767px) {
    .pulzion-div {
        margin: auto;
        max-width: 80%;
        text-align: center;
        border: 1px solid;
        border-radius: 3%;
        display: block;
        animation: animate 3s ease-in-out infinite;
    }

    .footfall {
        animation: animatecircle1 2s ease-in-out infinite;
    }

    .events {
        animation: animatecircle2 2s ease-in-out infinite;
    }
}

@keyframes slide-left {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

@keyframes animate {
    0% {
        transform: translateY(-5px);
    }

    50% {
        transform: translateY(5px);
    }

    100% {
        transform: translateY(-5px);
    }
}

@keyframes border-flicker {
    0% {
        opacity: 0.1;
        box-shadow: 0px 0px 10px 4px #1b96f3;
        transform: translateY(-5px);
    }

    2% {
        opacity: 1;
        box-shadow: 0px 0px 10px 2px #1b96f3;
    }
    4% {
        opacity: 0.1;
        box-shadow: 0px 0px 10px 2px #1b96f3;
    }
    50% {
        transform: translateY(5px);
    }
    8% {
        opacity: 1;
        box-shadow: 0px 0px 10px 2px #1b96f3;
    }
    70% {
        opacity: 0.7;
        box-shadow: 0px 0px 10px 2px #1b96f3;
    }
    100% {
        opacity: 1;
        box-shadow: 0px 0px 10px 2px #1b96f3;
        transform: translateY(-5px);
    }
}

@keyframes animatecircle1 {
    0% {
        transform: translateY(-10px);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes animatecircle2 {
    0% {
        transform: translateY(10px);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(10px);
    }
}

.diamond-pattern-about {
    height: 200px;
    width: 200px;
    position: absolute;
    left: -50px;
    z-index: 990;
    bottom: -600px;
}

.icon {
    margin-left: 10px;
    margin-top: 5px;
    text-decoration: none;
}

a {
    text-decoration: none;
}

@media only screen and (max-width: 1400px) {
    .diamond-pattern-about {
        display: none;
    }

    .pulzion-bg-box {
        display: none;
    }
}

@media only screen and (min-width: 1400px) {
    .pulzion-row-small {
        display: none;
    }
}

@media (min-width: 768px) {
    .container-about {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container-about {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container-about {
        width: 1170px;
    }
}

@media only screen and (max-width: 576px) {
    section {
        margin-top: 4rem;
        margin-bottom: 3.5rem;
    }
}

.team {
    background-color: unset;
    text-align: center;
    color: #ffffff;
    width: 90%;
    margin: auto;
}

.team .card.outer {
    margin: -15vw auto 5.5vw;
    padding: 15.5vw 1vw 1.5vw;
    border-radius: 2vw;
    background-color: #ffffff;
    width: 90%;
    box-shadow: 0px 0px 20px 2px rgba(0, 204, 255, 0.432);
    transition: linear 0.6s;
}

.team .card.inner {
    margin: auto;
    width: 85%;
    border-radius: 2vw;
    border: none;
    z-index: 1;
    transition: linear 0.6s;
}

.team .card.inner img {
    border-radius: 2vw;
    box-shadow: 0 0 8px 2px #0000007a;
    height: 22vw;
}

.t-name {
    font-size: 1.3rem;
    font-weight: 500;
    color: #ffffff;
}

.t-designation {
    font-size: 1.1rem;
    font-weight: 500;
    color: rgb(113, 200, 241);
    margin-bottom: 3vw;
}

.team .title {
    font-size: 3.5vw;
    font-weight: 600;
    color: #66ffff;
    margin-bottom: 3vw;
}

.team-member:hover .card.outer {
    box-shadow: 0px 0px 20px 2px rgba(0, 204, 255, 0.808);
}

.team-member {
    position: relative;
    margin: 4vw auto 3vw;
}

.Shapes {
    position: absolute;
    /* left: 0px; */
    /* top: 0px; */
    width: 8160px;
    height: 4166px;
    z-index: 27;
}

.Ellipse_1 {
    border-width: 10px;
    border-color: rgb(255, 255, 255);
    border-style: solid;
    border-radius: 50%;
    background-color: rgb(225, 225, 225);
    width: 80%;
    height: 80%;
    z-index: 25;
}

.team-member img {
    width: 65%;
    height: auto;
    border-radius: 100%;
    z-index: 30;
    /* position: absolute; */
    border: 0.5vw solid #ffffff;
}

.Ellipse_1_copy_5 {
    border-radius: 50%;
    background-color: rgb(113, 200, 241);
    opacity: 0.251;
    position: absolute;
    width: 67%;
    height: 105%;
    z-index: -1;
    top: 0;
    left: 10%;
}

.Ellipse_1_copy_4 {
    border-radius: 50%;
    background-color: rgb(113, 200, 241);
    opacity: 0.251;
    position: absolute;
    top: 5%;
    left: 20%;
    width: 67%;
    height: 100%;
    z-index: -1;
}

.Ellipse_1_copy_3 {
    border-radius: 50%;
    background-color: rgb(113, 200, 241);
    opacity: 0.251;
    position: absolute;
    bottom: 3%;
    width: 67%;
    height: 105%;
    left: 23%;
    z-index: -1;
}

.Ellipse_1_copy_2 {
    border-radius: 50%;
    background-color: rgb(24, 143, 203);
    opacity: 0.251;
    position: absolute;
    top: 5%;
    left: 15%;
    width: 67%;
    height: 102%;
    z-index: -1;
}

.Ellipse_1_copy_1 {
    border-radius: 50%;
    background-color: rgb(24, 143, 203);
    opacity: 0.251;
    position: absolute;
    bottom: 2%;
    left: 18%;
    width: 73%;
    height: 108%;
    z-index: -1;
}

.Ellipse_1_copy {
    border-radius: 50%;
    background-color: rgb(24, 143, 203);
    opacity: 0.251;
    position: absolute;
    bottom: 3%;
    left: 14%;
    width: 73%;
    height: 108%;
    z-index: -1;
}

@media (max-width: 600px) {
    .team {
        background-color: unset;
        text-align: center;
        color: #ffffff;
        width: 90%;
        margin: auto;
        /* background-image:url('./img/bg.jpg'); */
    }

    .team .card.outer {
        margin: -53vw auto 10.5vw;
        padding: 55.5vw 1vw 4.5vw;
        border-radius: 5vw;
        background-color: #ffffff;
        width: unset;
        box-shadow: 0px 0px 20px 2px rgba(0, 204, 255, 0.432);
    }

    .team .card.inner {
        margin: auto;
        width: 93%;
        border-radius: 5vw;
        border: none;
        z-index: 1;
    }

    .team .card.inner img {
        border-radius: 5vw;
        box-shadow: 0 0 8px 2px #0000007a;
        height: 65vw;
    }

    .team .card.outer .t-name {
        font-size: 5vw;
        font-weight: 600;
        color: #000000;
    }

    .team .card.outer .t-designation {
        font-size: 4vw;
        font-weight: 500;
        color: #56b0f5;
    }

    .team .title {
        font-size: 7.5vw;
        font-weight: 600;
        margin-bottom: 6vw;
    }
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.event-links {
  border: 1px solid #00ccff;
  padding: 0.75rem 3rem;
  border-radius: 100px;
  font-size: 1.2rem;
  transition: all 0.2s ease-in-out;
  color: #fff;
  background-color: rgb(27, 30, 31);
  margin: 0 0.4rem;
  margin-top: 20px;
}

.event-links1 {
  border: 1px solid #00ccff;
  padding: 0.25rem 3rem;
  border-radius: 100px;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  color: #fff;
  background-color: rgb(27, 30, 31);
  margin: 0 0.4rem;
  margin-top: 20px;
}

.event-links-disabled {
  background-color: #0f942e;
  opacity: 0.6;
  cursor: default !important;
}

.event-links-disabled:hover {
  background-color: #0f942e !important;
  opacity: 0.6 !important;
  cursor: default !important;
}

.otp-links-disabled {
  /*background-color: #0f942e;*/
  opacity: 0.6;
  cursor: default !important;
  border: none;
}

.otp-links-disabled:hover {
  /*background-color: #0f942e !important;*/
  background-color: rgb(27, 30, 31);
  opacity: 0.6 !important;
  cursor: default !important;
}

.event-links:hover {
  background-color: rgb(38, 41, 43);
  border-color: rgb(57, 62, 63);
}

.event-links1:hover {
  background-color: rgb(38, 41, 43);
  border-color: rgb(57, 62, 63);
}

.event-name {
  font-size: 1.5vw;
  color: #fff;
  margin-bottom: 1vw;
}

button {
  background-color: transparent;
  color: #fff;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.event-links-active {
  background-color: #00ccff;
  color: #000;
  font-weight: 500;
}

.event-links-active:hover {
  color: #fff;
}

.event-links-disabled {
  opacity: 0.5;
  cursor: default !important;
}

a.event-links:hover {
  background-color: #00ccff;
  text-decoration: none;
  color: inherit;
}

.hero-title {
  font-size: 3.2rem;
  text-align: center;
  margin: 3rem 0;
}

.hero-link-container {
  display: flex;
  flex-wrap: wrap;
  margin: 2vw 10%;
  justify-content: center;
}

.hero-link-container .event-links {
  margin: 1rem;
}

.e-card-container {
  margin: 3rem auto;
  width: 90%;
}

.e-card {
  display: flex;
  border-radius: 1rem;
  overflow: hidden;
  background-color: #000;
  margin: 1em 0;
  max-height: unset !important;
  box-shadow: 0 0 16px 0px #00ccff;
  width: 90%;
  margin: 2vw auto !important;
  padding: 1vw;
  cursor: pointer;
  height: 100%;
}

.e-card .event-links {
  font-size: 1rem;
  padding: 0.5rem 3rem;
}

.event-info {
  font-size: 1rem;
  color: #fff;
  margin-bottom: 15px;
}

@media (min-width: 600px) {
  .e-card {
    max-height: 500px;
  }
}

@media (min-width: 1200px) {
  .e-card {
    max-height: 300px;
    margin: 2em 0;
  }
}

.e-card:hover {
  box-shadow: none;
  transform: scale(1.015);
  background-color: #00ccff99;
  transition: background-color 1000ms ease-out;
}

.e-card:hover .event-name {
  color: black;
}

/* .e-card-text {
  padding: 0 0 3vw;
} */
@media (min-width: 1200px) {
  .e-card-text {
    padding: 0;
  }
}

.e-card-text p {
  margin: 0 auto 2vw;
  font-size: 2vw;
  color: #fff;
  line-height: 2.5rem;
}

.e-card img {
  width: 60%;
  height: auto;
  margin: auto;
  /* border-radius: 100vw; */
}

.e-card-buttons .link-event {
  padding: 0.7vw 2vw;
  margin: 0 auto;
  min-width: 50%;
}

.e-card-buttons button:hover {
  /* background-color: #00ccff;
    color: #131313; */
  /* font-size: 1.2rem; */
}

.popup-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  transition: all 0.3s ease-in-out;
  color: #000 !important;
  z-index: 1100;
}

.popup {
  background-color: #fff;
  /* border-radius: 2rem; */
  padding: 2rem;
  z-index: 1101;
  width: 670px;
  /* box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.774); */
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.popup h1 {
  margin-top: 0;
}

@media (max-width: 600px) {
  .popup {
    width: 95%;
    /* border-radius: 5vw; */
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 95%;
  }

  .e-card-img {
    width: 300px;
    min-height: 300px;
  }

  /* .e-card-buttons .link-event{
        padding: 10px 10px;
        margin: -10px;
        min-width: 100%;
      } */
}

.primary {
  background-color: #8bc7f5;
}

/* @media screen and (max-width: 768px) {
  .e-card-buttons .link-event{
    padding: 0px 5px;
    margin: -25px;
    min-width: 100%;
  }
} */

@media screen and (max-width: 750px) {
  html {
    font-size: 80%;
  }

  .e-card {
    /* flex-direction: column; */
  }

  .e-card-img {
    width: 100%;
    max-height: 200px;
  }

  /* .e-card-text {
        padding: 2rem;
      } */
  .e-card-text p {
    margin-bottom: 1rem;
  }
}

@media (max-width: 600px) {
  .e-card-buttons .link-event {
    margin-bottom: 7vw;
    padding: 2vw 4vw;
  }

  .e-card {
    margin: 5vw auto !important;
  }

  .event-name {
    font-size: 5vw;
    margin-bottom: 4vw;
  }

  /* .popup{
        top: 2%;
       transform: translateY(0%);
      } */
  .e-card-container {
    top: 0;
    /* height: 1000px; */
    /* overflow: hidden; */
  }
}

/* @-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 1px rgba(44, 172, 204, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 7px rgba(44, 180, 204, 0.685);
  }
  100% {
      -webkit-box-shadow: 0 0 0 1px rgba(44, 185, 204, 0.562);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 1px rgba(44, 204, 196, 0.4);
      background-color: black;
      box-shadow: 0 0 0 1px rgba(44, 180, 204, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 7px rgba(44, 204, 204, 0.486);
      background-color: rgba(74, 104, 105, 0.671);
      box-shadow: 0 0 0 7px rgba(44, 199, 204, 0.055);
  }
  100% {
      -moz-box-shadow: 0 0 0 1px rgba(44, 172, 204, 0.452);
      background-color: black;
      box-shadow: 0 0 0 1px rgba(44, 172, 204, 0.445);
  }
} */

#style-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-2::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #00ccff;
}

.pop-container {
  margin-top: 200px;
}

.pop-container {
  position: relative;
  display: inline;
}

.pop-content {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -80px;
  margin-bottom: 10px;
  z-index: 100;
  color: gray;
  width: 230px;
  text-align: center;
  padding: 12px 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.5s;
}

span.warn {
  color: yellow;
  display: inline-block;
}

span.warn::after {
  content: "\E62C";
  position: absolute;
}

.hidden {
  visibility: visible;
  transform: scale(1);
  opacity: 1;
}

.text-content::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.text-content {
  padding: 4px 0px 12px;
  text-align: left;
}

.actions {
  text-align: right;
}

.actions button {
  padding: 3px 7px;
  margin-left: 8px;
  border-radius: 4px;
  background-color: #fff;
  background-image: none;
  display: inline-block;
  color: rgba(0, 0, 0, 0.65);
  outline: 0px;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;
}

.actions button:hover {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
}

.sponsor {
  background-color: unset;
  text-align: center;
  color: #ffffff;
  width: 90%;
  margin: auto;
}

.sponsor .title {
  font-size: 3.5vw;
  font-weight: 600;
  color: #66ffff;
  margin-bottom: 3vw;
}

.title-sponsor img {
  margin-top: 25px;
  width: 100%;
}

.sponsor-tile {
  margin-top: 7vh !important;
}

:root {
  --border-width: 1px;
}

@font-face {
  font-family: Cruiser;
  /*src: url('./2015\ Cruiser\ Bold.ttf');*/
}

@font-face {
  font-family: Prototype;
  src: url(/static/media/Prototype.be9924a4.ttf);
}

@font-face {
  font-family: Highland;
  src: url(/static/media/HighlandGothicFLF-Bold.ee569e4c.ttf);
}

@font-face {
  font-family: Roboto;
  src: url(/static/media/Roboto-Light.03e629f6.ttf);
}

.main {
  overflow: hidden;
  z-index: 10;
  width: 100%;
  margin: auto;
  /* background-image: url('https://beta.pulzion.in/assets/images/home-blo-cyber-ecurity (1).png'); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  padding: 0 5%;
  height: 85vh;
}

.home-img {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.home-img-mob {
  position: absolute;
  bottom: 0;
  opacity: 0.5;
  display: none;
}

@media (max-width: 600px) {
  .home-img {
    display: none;
  }

  .home-img-mob {
    display: block;
  }

  .main {
    background-image: none;
  }
}

@media (min-width: 600px) and (min-height: 1000px) {
  .home-img {
    display: none;
  }

  .main {
    background-position: right;
    height: unset;
    padding-bottom: 20vw;
    background-size: contain;
  }
}

@keyframes switch {
  0% {
    opacity: 0;
    -webkit-filter: blur(20px);
            filter: blur(20px);
    transform: scale(2);
  }
  3% {
    opacity: 1;
    -webkit-filter: blur(0);
            filter: blur(0);
    transform: scale(1);
  }
}

.main .title {
  font-family: Highland;
  /* font-weight: bold; */
  position: absolute;
  z-index: 100;
  /* font-size: 7vw; */
  text-align: left;
  margin: auto;
  margin-top: 0;
  color: #fff;
  letter-spacing: 0.5vw;
}

.main .title .hii {
  font-size: 7vw;
}

.main .subtitle {
  font-family: Highland;
  font-size: 2vw;
  text-align: left;
  margin: auto;
  color: rgba(22, 164, 189, 0.9);
  font-weight: lighter;
  margin-top: 18%;
}

.main .coming-soon {
  font-family: Highland;
  font-size: 1.5rem;
  text-align: left;
  margin: auto;
  color: rgba(22, 164, 189, 0.9);
  font-weight: lighter;
  margin-top: 35%;
}

.title {
  opacity: 1;
  animation: switch 12s linear;
}

/* 
.bgimg{
	background-image: linear-gradient(to top, rgba(14, 0, 0, 0.5), rgba(12, 0, 0, 0.856)),url('../Bg/home-blo-cyber-ecurity.png') !important;
  background-size: cover;
  background-attachment:fixed;
  background-repeat: no-repeat;
  background-color: #010d1a;
  background-position: right;
} */

.reg-btn button {
  position: relative;
  border: none;
  font-size: 1vw;
  transition: color 0.5s, transform 0.2s, background-color 0.2s;
  outline: none;
  border-radius: 10px;
  margin: 0 10px;
  padding: 1vw 1vw;
  border: 0.2vw solid transparent;
  margin-top: 7vw;
  letter-spacing: 0.3vw;
  font-weight: bold;
}

.reg-btn button::after,
.reg-btn button::before {
  border-radius: 3px;
}

.shrink-border {
  background-color: rgba(22, 164, 189, 0.9);
  color: #fff;
}

.shrink-border:hover {
  background-color: transparent;
  box-shadow: none;
  color: #ffffff;
}

.shrink-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border: 0.2vw solid #02fff218; */
  transition: opacity 0.3s, border 0.3s;
}

.shrink-border:hover::before {
  opacity: 0;
}

.shrink-border::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0.1vw solid #00ccff;
  opacity: 0;
  z-index: -1;
  transform: scaleX(1.1) scaleY(1.3);
  transition: transform 0.3s, opacity 0.3s;
}

.shrink-border:hover::after {
  opacity: 1;
  transform: scaleX(1) scaleY(1);
}

.material-bubble {
  background-color: transparent;
  color: #1b1b20;
  border: none;
  overflow: hidden;
  box-shadow: none;
}

.material-bubble:hover {
  color: #e6e6e6;
}

.material-bubble::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0.2vw solid #1b1b20;
  transition: opacity 0.3s, border 0.3s;
}

.material-bubble:hover::before {
  opacity: 0;
}

.material-bubble::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  background-color: #494958;
  border-color: transparent;
  border-radius: 50%;
  transform: translate(-10px, -70px) scale(0.1);
  opacity: 0;
  z-index: -1;
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}

.material-bubble:hover::after {
  opacity: 1;
  transform-origin: 100px 100px;
  transform: scale(1) translate(-10px, -70px);
}

.desktop-footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  margin: 0 !important;
  display: none;
}

.mobile-footer {
  display: block;
  padding: 10px 20px;
  margin: 0 !important;
  display: none;
}

@media (max-width: 1031px) {
  .mobile-footer {
    display: none;
  }

  .desktop-footer {
    display: none;
  }
}

.register-btn:hover {
  text-decoration: none;
  color: #1b96f3;
}

.mobile-view {
  display: none;
}

.main-left-div {
  flex: 1 1;
}

.main-right-div {
  flex: 1 1;
}

.main-right-div {
  overflow: hidden;
}

.design-block {
  display: none;
  height: 200px;
  width: 200px;
  position: absolute;
  background-color: #c3073f;
  top: 10%;
}

/* .hero-text {
  position: absolute;
  width: 60%;
  left: 8%;
  top: 15%;
  z-index: 100;
} */

.event-description {
  background-color: white;
  padding: 15px;
  position: absolute;
  font-weight: bold;
  font-size: 96%;
  width: 150px;
  height: 150px;
  right: 40px;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.event-description div:nth-child(1),
.event-description div:nth-child(2) {
  word-wrap: break-word;
}

.event-description .red-line {
  height: 5px;
  width: 70%;
  /* margin-left: 20px; */
  background-color: #1b96f3;
  margin-top: 5px;
}

.diamond-pattern {
  height: 200px;
  width: 200px;
  position: absolute;
  left: -50px;
  z-index: 990;
  bottom: -50px;
}

.register-btn {
  text-decoration: none;
  color: #1b96f3;
  z-index: 20;
  padding: 20px 40px;
  background-color: #2b2b2c;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
}

.register-btn-div {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 130%;
  background-color: #2b2b2c;
  text-align: center;
  width: 40%;
  margin-top: 53%;
  margin-left: 60%;
  transform: translateX(-50%);
  z-index: 20;
}

.mobile-register-btn-div {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 110%;
  background-color: #2b2b2c;
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.mobile-hero-text,
.mobile-20-text {
  display: flex;
  justify-content: center;
  padding-bottom: 35px;
}

@media only screen and (max-width: 1282px) {
  .main-right-div {
    flex: 2 1;
  }

  .text-20 {
    width: 102%;
    transform: translateX(10%);
    z-index: 1;
  }

  .register-btn-div {
    margin-top: 100%;
    width: 60%;
    font-size: 105%;
  }
}

@media only screen and (max-width: 1030px) {
  /* .main-right-div{
            flex: 2;
        } */
  .hero-text {
    width: 60%;
  }

  .text-20 {
    width: 100%;
    height: 90%;
    transform: translateX(80px);
    z-index: 1;
  }

  .register-btn-div {
    margin-top: 100%;
    width: 70%;
    font-size: 105%;
  }
}

@media only screen and (max-width: 949px) {
  .main-right-div {
    flex: 2 1;
  }

  .text-20 {
    width: 102%;
    height: 85%;
    transform: translateX(10%);
    z-index: 1;
  }

  .register-btn-div {
    margin-top: 127%;
    width: 70%;
    font-size: 105%;
  }
}

@media only screen and (max-width: 772px) {
  .register-btn-div {
    width: 90%;
    font-size: 100%;
  }
}

@media only screen and (max-width: 609px) {
  .diamond-pattern {
    height: 150px;
    width: 150px;
    left: -30px;
    bottom: -30px;
  }

  .main {
    flex-direction: column;
  }

  .mobile-view {
    display: block;
    margin-top: 20px;
  }

  .main-right-div,
  .main-left-div {
    display: none;
  }

  .event-description {
    height: 100px;
    width: 100px;
    font-size: 70%;
    padding: 13px;
    right: 25px;
    bottom: 25px;
  }
}

@media only screen and (max-width: 609px) and (min-width: 350px) {
  .mobile-view {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

/* .reg-btn button-animation */

.mobile-register-btn-div p,
.register-btn-div p {
  margin: 0 !important;
}

.mobile-register-btn-div::after,
.register-btn-div::after {
  position: absolute;
  content: "";
  top: calc(-1 * 1px);
  top: calc(-1 * var(--border-width));
  left: calc(-1 * 1px);
  left: calc(-1 * var(--border-width));
  z-index: 1;
  width: calc(100% + 1px * 2);
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + 1px * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient(
    60deg,
    hsl(224, 85%, 66%),
    hsl(269, 85%, 66%),
    hsl(314, 85%, 66%),
    #5f9ff2,
    hsl(239, 85%, 66%),
    hsl(89, 85%, 66%),
    hsl(199, 85%, 66%),
    hsl(179, 85%, 66%)
  );
  background-size: 300% 300%;
  background-position: 0 50%;
  border-radius: calc(2 * 1px);
  border-radius: calc(2 * var(--border-width));
  animation: moveGradient 4s alternate infinite;
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

#your-element-selector {
  width: 100vw;
}

.subtitle {
  position: absolute;
  z-index: 10;
  margin-top: 35%;
}

.coming-soon {
  position: absolute;
  z-index: 10;
  margin-top: 45%;
}

@media (max-width: 600px) {
  #your-element-selector {
    width: 98vw;
    opacity: 0.6;
  }

  .main {
    margin: 0 auto;
    text-align: center;
    opacity: 1;
  }

  .main .title {
    text-align: center;
    width: 90vw;
    /* font-size: 12vw; */
    margin-bottom: unset;
    margin-top: 25%;
  }

  .main .title .hii {
    font-size: 12vw;
  }

  .main .subtitle {
    text-align: center;
    width: 90vw;
    padding: 0;
    font-size: 4.5vw;
    margin-bottom: unset;
    margin-top: 70vw;
    color: #fff;
  }
  .main .coming-soon {
    text-align: center;
    width: 90vw;
    padding: 0;
    font-size: 4.5vw;
    margin-bottom: unset;
    margin-top: 59vh;
    color: #fff;
  }
  /* style={{ marginTop: "35%", fontSize: "1.5rem" }} */

  .reg-btn button {
    font-size: 4.3vw;
    padding: 5.5vw 4vw;
    border: 0.2vw solid transparent;
    letter-spacing: 0.3vw;
    margin: auto;
  }

  .reg-btn {
    margin-top: 20vw;
    opacity: 1;
    position: absolute;
    bottom: 5vh;
    left: 30%;
    border: 0.3vw solid #00ccff;
    border-radius: 1vw;
  }

  .shrink-border {
    background-color: #0000009a;
    color: rgba(22, 164, 189, 0.9);
  }

  .shrink-border:hover {
    background-color: transparent;
    box-shadow: none;
    color: #ffffff;
  }
}

.title img {
  width: 60%;
}

.subtitle img {
  width: 75%;
}

@media (max-width: 600px) {
  .subtitle img {
    width: 40%;
  }
  .coming-soon {
    display: none;
  }
}

.sponsor {
    background-color: unset;
    text-align: center;
    color: #FFFFFF;
    width: 90%;
    margin: auto;
}

.sponsor .title {
    font-size: 3.5vw;
    font-weight: 600;
    color: #66ffff;
    margin-bottom: 3vw;
}
.sponsor {
    background-color: unset;
    text-align: center;
    color: #FFFFFF;
    width: 90%;
    margin: auto;
}

.sponsor .title {
    font-size: 3.5vw;
    font-weight: 600;
    color: #66ffff;
    margin-bottom: 3vw;
}
