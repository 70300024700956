@import url(https://fonts.googleapis.com/css?family=Abel);

.loading {
    height: 10vw;
    width: 10vw;
    position: relative;
}

.loading:nth-child(2n) {
    top: 20%;
    left: 20%;
    transform: translate(0%, -50%);
}

.loading:nth-child(2n + 1) {
    margin-top: 20%;
    left: 20%;
    transform: translate(0%, 50%);
}

.loading:after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: " ";
    box-shadow: 0 0 0 2px rgba(0, 225, 255, 0.712);
    transform: rotate(45deg);
    background-color: rgba(1, 30, 34, 0.281);
}

.dots {
    text-align: center;
    margin-top: 1.5vw;
}

@keyframes dot-show-hide-1 {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    20% {
        opacity: 1;
    }

    30% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    60% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    80% {
        opacity: 0;
    }

    90% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes dot-show-hide-2 {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    30% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    60% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    80% {
        opacity: 0;
    }

    90% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes dot-show-hide-3 {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    40% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    60% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    80% {
        opacity: 0;
    }

    90% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.dots i {
    display: inline-block;
    vertical-align: top;
    height: 3px;
    width: 3px;
    background: #fff;
    margin: 0 8px;
    opacity: 0;
}

.dots i:nth-child(1) {
    animation: dot-show-hide-1 1.5s linear infinite forwards;
}

.dots i:nth-child(2) {
    animation: dot-show-hide-2 1.5s linear infinite forwards;
}

.dots i:nth-child(3) {
    animation: dot-show-hide-3 1.5s linear infinite forwards;
}

.dots i.stop-animating {
    animation-play-state: paused;
}

.numbers span {
    font-family: "Abel";
    font-size: 2vw !important;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    font-weight: bold;
    transform: translatey(-50%);
    margin-top: -1.5vw;
    color: #fff;
}

.name {
    color: #fff;
    font-size: 1.2vw;
    margin-top: 2vw;
}

@media (max-width: 600px) {
    .number {
        padding: 5vw 0;
    }

    .loading {
        width: 20vw;
        height: 20vw;
    }

    .loading:nth-child(2n + 1) {
        left: 18%;
    }

    .loading:nth-child(2n) {
        left: 18%;
    }

    .numbers span {
        font-size: 5.5vw !important;
        margin-bottom: 2vw;
    }

    .name {
        font-size: 3vw;
        position: absolute;
        top: 47%;
        left: 50%;
        transform: translateX(-50%);
    }

    .dots i {
        margin: 1vw;
    }
}
