.team {
    background-color: unset;
    text-align: center;
    color: #ffffff;
    width: 90%;
    margin: auto;
}

.team .card.outer {
    margin: -15vw auto 5.5vw;
    padding: 15.5vw 1vw 1.5vw;
    border-radius: 2vw;
    background-color: #ffffff;
    width: 90%;
    box-shadow: 0px 0px 20px 2px rgba(0, 204, 255, 0.432);
    transition: linear 0.6s;
}

.team .card.inner {
    margin: auto;
    width: 85%;
    border-radius: 2vw;
    border: none;
    z-index: 1;
    transition: linear 0.6s;
}

.team .card.inner img {
    border-radius: 2vw;
    box-shadow: 0 0 8px 2px #0000007a;
    height: 22vw;
}

.t-name {
    font-size: 1.3rem;
    font-weight: 500;
    color: #ffffff;
}

.t-designation {
    font-size: 1.1rem;
    font-weight: 500;
    color: rgb(113, 200, 241);
    margin-bottom: 3vw;
}

.team .title {
    font-size: 3.5vw;
    font-weight: 600;
    color: #66ffff;
    margin-bottom: 3vw;
}

.team-member:hover .card.outer {
    box-shadow: 0px 0px 20px 2px rgba(0, 204, 255, 0.808);
}

.team-member {
    position: relative;
    margin: 4vw auto 3vw;
}

.Shapes {
    position: absolute;
    /* left: 0px; */
    /* top: 0px; */
    width: 8160px;
    height: 4166px;
    z-index: 27;
}

.Ellipse_1 {
    border-width: 10px;
    border-color: rgb(255, 255, 255);
    border-style: solid;
    border-radius: 50%;
    background-color: rgb(225, 225, 225);
    width: 80%;
    height: 80%;
    z-index: 25;
}

.team-member img {
    width: 65%;
    height: auto;
    border-radius: 100%;
    z-index: 30;
    /* position: absolute; */
    border: 0.5vw solid #ffffff;
}

.Ellipse_1_copy_5 {
    border-radius: 50%;
    background-color: rgb(113, 200, 241);
    opacity: 0.251;
    position: absolute;
    width: 67%;
    height: 105%;
    z-index: -1;
    top: 0;
    left: 10%;
}

.Ellipse_1_copy_4 {
    border-radius: 50%;
    background-color: rgb(113, 200, 241);
    opacity: 0.251;
    position: absolute;
    top: 5%;
    left: 20%;
    width: 67%;
    height: 100%;
    z-index: -1;
}

.Ellipse_1_copy_3 {
    border-radius: 50%;
    background-color: rgb(113, 200, 241);
    opacity: 0.251;
    position: absolute;
    bottom: 3%;
    width: 67%;
    height: 105%;
    left: 23%;
    z-index: -1;
}

.Ellipse_1_copy_2 {
    border-radius: 50%;
    background-color: rgb(24, 143, 203);
    opacity: 0.251;
    position: absolute;
    top: 5%;
    left: 15%;
    width: 67%;
    height: 102%;
    z-index: -1;
}

.Ellipse_1_copy_1 {
    border-radius: 50%;
    background-color: rgb(24, 143, 203);
    opacity: 0.251;
    position: absolute;
    bottom: 2%;
    left: 18%;
    width: 73%;
    height: 108%;
    z-index: -1;
}

.Ellipse_1_copy {
    border-radius: 50%;
    background-color: rgb(24, 143, 203);
    opacity: 0.251;
    position: absolute;
    bottom: 3%;
    left: 14%;
    width: 73%;
    height: 108%;
    z-index: -1;
}

@media (max-width: 600px) {
    .team {
        background-color: unset;
        text-align: center;
        color: #ffffff;
        width: 90%;
        margin: auto;
        /* background-image:url('./img/bg.jpg'); */
    }

    .team .card.outer {
        margin: -53vw auto 10.5vw;
        padding: 55.5vw 1vw 4.5vw;
        border-radius: 5vw;
        background-color: #ffffff;
        width: unset;
        box-shadow: 0px 0px 20px 2px rgba(0, 204, 255, 0.432);
    }

    .team .card.inner {
        margin: auto;
        width: 93%;
        border-radius: 5vw;
        border: none;
        z-index: 1;
    }

    .team .card.inner img {
        border-radius: 5vw;
        box-shadow: 0 0 8px 2px #0000007a;
        height: 65vw;
    }

    .team .card.outer .t-name {
        font-size: 5vw;
        font-weight: 600;
        color: #000000;
    }

    .team .card.outer .t-designation {
        font-size: 4vw;
        font-weight: 500;
        color: #56b0f5;
    }

    .team .title {
        font-size: 7.5vw;
        font-weight: 600;
        margin-bottom: 6vw;
    }
}
