* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* border: solid 1px red; */
}

body {
    overflow-x: hidden !important;
    margin: 0;
    max-width: 100vw !important;
}

/* width */
::-webkit-scrollbar {
    width: 12px;
    background: black;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(22, 164, 189, 0.9);
    border-radius: 10px;
}

.nGY2GThumbnail {
    border-color: transparent !important;
    border-radius: 10px !important;
}

.privacy-policy {
    color: white;
    margin: 5%;
}

.Page404 {
    text-align: center;
    color: white;
    margin: 5%;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
@font-face {
    font-family: SegoeUI !important;
    src: local("Segoe UI Light"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf) format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: SegoeUI;
    src: local("Segoe UI Semilight"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf) format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: SegoeUI;
    src: local("Segoe UI"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: SegoeUI;
    src: local("Segoe UI Semibold"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf) format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: SegoeUI;
    src: local("Segoe UI Bold"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf) format("truetype");
    font-weight: 700;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap");

body {
    font-family: "Segoe UI";
}
