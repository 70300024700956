.main-about {
    flex: 2;
    overflow: hidden;
    z-index: 10 !important;
}

/*
span {
  font-size: 18px;
} */

.btn-about {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    outline: none !important;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.container-about {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

/* body {
  font-family: 'Roboto Slab', serif;
  color: white;
  background-color: black;
} */

section {
    margin-top: 4rem;
    margin-bottom: 7rem;
}

h1 {
    font-size: 3rem;
    margin-bottom: 0;
    margin-top: 0;
}

h2 {
    padding-top: 3%;
    font-size: 32px;
}

/* Heading */

.main-h1-div {
    text-align: center;
    position: absolute;
    /* border:3px solid #1b96f3; */
    width: 300px;
    /* border-radius:20px; */
    left: 50%;
    margin: -50px 0 0 -150px;
    /* -moz-box-shadow: 0px 0px 5px 2px #1b96f3;
      box-shadow: 0px 0px 5px 2px #1b96f3; */
}

.About-Us {
    color: #66ffff;
    font-size: 3rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.header {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: white;
}

.haeder-block {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #1b96f3;
}

/* Pulzion */
.pulzion-bg-box {
    background-color: #004d88;
    margin-top: 10px;
    padding: 0px;
    height: 285px;
    border-radius: 5%;
}

.pulzion-div {
    margin: auto;
    display: block;
    margin-top: -14px;
    max-width: 75%;
    text-align: center;
    border-radius: 6%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    background-color: #1b96f3;
    margin-bottom: 50px;
    border-top: 15px solid #1a1a1a;
    border-bottom: 15px solid #1a1a1a;
    border-right: 5px solid #1a1a1a;
    border-left: 5px solid #1a1a1a;
}

.pulzion-row-small {
    display: unset;
}

.pulzion-heading {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-top: -10px;
    font-size: 225%;
    color: #0c314e;
}

.pulzion-text {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 3%;
    font-size: 118%;
    text-align: center;
    color: #0c314e;
}

/* Pasc and ACM */
.col-lg-4 {
    text-align: center;
    margin-bottom: 2rem;
}

.about-div {
    margin: auto;
    margin-bottom: 2rem;
    max-width: 315px;
    text-align: center;
    /* border: 2px solid #1b96f3; */
    border-radius: 0.6vw;
    display: block;
    /* animation: animate 3s ease-in-out infinite; */
    /* animation: border-flicker 3s linear infinite; */
    background-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.548),
            rgba(0, 0, 0, 0.425)
    ),
    url("./images/footer_lodyas.png");
    padding: 2vw;
    height: 100%;
}

.about-text {
    font-size: 18px;
    color: white;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: lighter;
}

#more1 {
    display: none;
}

#more2 {
    display: none;
}

#more3 {
    display: none;
}

.pasc-img {
    margin-top: 1rem;
    max-width: 70%;
}

.acm-img {
    max-width: 50%;
}

.acmw-img {
    max-width: 70%;
}

#pasc-acm-acmw-section {
    margin-bottom: 1rem;
}

.circle {
    margin: auto;
    margin-bottom: 15px;
    width: 130px;
    height: 130px;
    text-align: center;
    opacity: 0.8;
    background-image: url("./images/dark-honeycomb.png");
    border: 3px solid #1b96f3;
    border-radius: 100px;
    box-shadow: 0px 0px 20px 4px #1b96f3;
    position: relative;
    animation: animatecircle1 2s ease-in-out infinite;
}

.members {
    animation: animatecircle1 2s ease-in-out infinite;
}

.events {
    animation: animatecircle1 2s ease-in-out infinite;
}

.footfall {
    animation: animatecircle2 2s ease-in-out infinite;
}

.icon {
    margin-top: 15%;
    text-decoration: none;
}

/* Contact-us */
#contact-us-section {
    margin-bottom: 0;
}

.Contact-Us {
    text-align: center;
    margin-bottom: 100px;
    color: white;
}

.contact-us-container {
    text-align: center;
    padding-bottom: 4rem;
}

.contact-us-text {
    color: white;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 15px;
    font-size: 0.75rem !important;
}

/* Address */
.address-content {
    text-align: center;
    margin: 0px;
    display: block;
}

@media screen and (max-width: 767px) {
    .pulzion-div {
        margin: auto;
        max-width: 80%;
        text-align: center;
        border: 1px solid;
        border-radius: 3%;
        display: block;
        animation: animate 3s ease-in-out infinite;
    }

    .footfall {
        animation: animatecircle1 2s ease-in-out infinite;
    }

    .events {
        animation: animatecircle2 2s ease-in-out infinite;
    }
}

@keyframes slide-left {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

@keyframes animate {
    0% {
        transform: translateY(-5px);
    }

    50% {
        transform: translateY(5px);
    }

    100% {
        transform: translateY(-5px);
    }
}

@keyframes border-flicker {
    0% {
        opacity: 0.1;
        -webkit-box-shadow: 0px 0px 10px 4px #1b96f3;
        -moz-box-shadow: 0px 0px 10px 4px #1b96f3;
        box-shadow: 0px 0px 10px 4px #1b96f3;
        transform: translateY(-5px);
    }

    2% {
        opacity: 1;
        -webkit-box-shadow: 0px 0px 10px 2px #1b96f3;
        -moz-box-shadow: 0px 0px 10px 2px #1b96f3;
        box-shadow: 0px 0px 10px 2px #1b96f3;
    }
    4% {
        opacity: 0.1;
        -webkit-box-shadow: 0px 0px 10px 2px #1b96f3;
        -moz-box-shadow: 0px 0px 10px 2px #1b96f3;
        box-shadow: 0px 0px 10px 2px #1b96f3;
    }
    50% {
        transform: translateY(5px);
    }
    8% {
        opacity: 1;
        -webkit-box-shadow: 0px 0px 10px 2px #1b96f3;
        -moz-box-shadow: 0px 0px 10px 2px #1b96f3;
        box-shadow: 0px 0px 10px 2px #1b96f3;
    }
    70% {
        opacity: 0.7;
        -webkit-box-shadow: 0px 0px 10px 2px #1b96f3;
        -moz-box-shadow: 0px 0px 10px 2px #1b96f3;
        box-shadow: 0px 0px 10px 2px #1b96f3;
    }
    100% {
        opacity: 1;
        -webkit-box-shadow: 0px 0px 10px 2px #1b96f3;
        -moz-box-shadow: 0px 0px 10px 2px #1b96f3;
        box-shadow: 0px 0px 10px 2px #1b96f3;
        transform: translateY(-5px);
    }
}

@keyframes animatecircle1 {
    0% {
        transform: translateY(-10px);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes animatecircle2 {
    0% {
        transform: translateY(10px);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(10px);
    }
}

.diamond-pattern-about {
    height: 200px;
    width: 200px;
    position: absolute;
    left: -50px;
    z-index: 990;
    bottom: -600px;
}

.icon {
    margin-left: 10px;
    margin-top: 5px;
    text-decoration: none;
}

a {
    text-decoration: none;
}

@media only screen and (max-width: 1400px) {
    .diamond-pattern-about {
        display: none;
    }

    .pulzion-bg-box {
        display: none;
    }
}

@media only screen and (min-width: 1400px) {
    .pulzion-row-small {
        display: none;
    }
}

@media (min-width: 768px) {
    .container-about {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container-about {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container-about {
        width: 1170px;
    }
}

@media only screen and (max-width: 576px) {
    section {
        margin-top: 4rem;
        margin-bottom: 3.5rem;
    }
}
