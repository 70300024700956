.App {
    background-color: #000000;
    width: 100vw;
    min-height: 100vh;
    /* background-attachment:fixed; */
    /* display: flex;
    flex-direction: column; */
    margin: 0;
    overflow-x: hidden !important;
}

/* 
@media (max-width:600px){
  .App{
    background-image: linear-gradient(to top, rgba(14, 0, 0, 0.5), rgba(12, 0, 0, 0.856)),url('./Bg/mobilebg.png') !important;
    background-position: center;

  }
} */

footer {
    padding: 20px 0;
    text-align: center;
    color: white;
    margin-bottom: 30px;
    margin-top: auto;
    display: none;
}

@media (max-height: 740px) {
    .App {
        min-height: 100vh;
    }
}

@media (max-height: 570px) {
    .App {
        height: 100vh;
    }
}

.reg-button {
    margin: auto;
    text-align: center;
    margin: 5vw 0;
    font-size: 1.5vw;
}

.page-title {
    /* font-family: Cruiser !important; */
    font-family: "Montserrat", sans-serif !important;
    color: rgb(0, 216, 224) !important;
    font-size: 3vw !important;
    margin: 2vw 0;
}

@media (max-width: 600px) {
    .page-title {
        font-size: 8vw !important;
        margin: 6vw 0;
    }
}
